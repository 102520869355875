import { PageHeading } from '@vccp/bernadette-components';
import { graphql } from 'gatsby';
import React from 'react';
import * as styles from './thinking.module.scss';
import ContactUs from '../components/ContactUs/ContactUs';
import ContentfulArticleSummary from '../components/ContentfulArticleSummary/ContentfulArticleSummary';
import ContentfulQuote from '../components/ContentfulQuote/ContentfulQuote';
import FeaturedThinkingArticle from '../components/FeaturedThinkingArticle/FeaturedThinkingArticle';
import Layout from '../components/Layout/Layout';
import PageTransition from 'gatsby-plugin-page-transitions';

const ThinkingPage = ({ data }) => {
  const quoteData = data.contentfulComponentQuote;
  return (
    <PageTransition>
      <Layout className={styles.thinkingHeader}>
        <PageHeading
          title="Thinking & News"
          fullWidthHeading={true}
          description="News, thinking, inspiration, analysis and ideas. Find out more about what makes Bernadette tick."
        />
        <FeaturedThinkingArticle />
        <ContentfulArticleSummary />
        <ContactUs />
      </Layout>
    </PageTransition>
  );
};

export default ThinkingPage;

export const query = graphql`
  query ThinkingPageQuery {
    contentfulComponentQuote {
      jobTitle
      image {
        gatsbyImageData
        title
      }
      reversed
      quotee
      backgroundImageOptions
      narrowQuote
      quote {
        quote
      }
    }
  }
`;
