import React, { useEffect, useRef, useState } from 'react';
import * as styles from './FeaturedThinkingArticle.module.scss';
import cx from 'classnames';
import * as stylesReadMore from '../MoreLink/MoreLink.module.scss';
import { NeutralPaletteNeutral10 } from '../../styles/tokens/variables';
import { ArrowIcon } from '@vccp/bernadette-components';
import {
  CircleContainer,
  Content,
  PageHeading,
} from '@vccp/bernadette-components';
import { graphql, useStaticQuery } from 'gatsby';
import Image from '../Image/Image';
import MoreLink from '../MoreLink/MoreLink';
import LottieAnimation from '../ContentfulLottieAnimation/LottieAnimation';
import useMediaQuery from '../../hooks/useMediaQuery';
import InternalLink from '../InternalLink/InternalLink';
//const animation = '/assets/animations/thinking_header_rocket-pullback.json';
const animation =
  '/assets/animations/thinking_header_rocket-pullback-animation.json';
const FeaturedThinkingArticle = () => {
  const data = useStaticQuery(featuredThinkingArticleyQuery);
  const article = data.allContentfulThinking.nodes[0];
  const heading = data.contentfulComponentPageHeader;
  const animationRef = useRef();
  const [progress, setProgress] = useState(0);
  const [offset, setOffset] = useState(0);
  const [coef, setCoef] = useState(0);
  const animationHeight = animationRef?.current?.getBoundingClientRect().height;
  useEffect(() => {
    const scrollHandler = () => {
      const newProgress = Math.min(
        (coef * window.scrollY) / (window.innerHeight / 2),
        1,
      );
      setProgress(newProgress);
      setOffset((1 - newProgress) * animationHeight);
      setCoef(window.innerHeight / window.innerWidth);
    };
    scrollHandler();

    // Needs update on every scroll, don't throttle
    window.addEventListener('scroll', scrollHandler);
    window.addEventListener('resize', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
      window.removeEventListener('resize', scrollHandler);
    };
  }, [coef, progress, offset, animationHeight]);

  return (
    <div
      style={{
        marginBottom: `-${(offset - 120 / coef) / 16}rem`,
      }}>
      <div className={styles.thinkingArticleWrapper}>
        <InternalLink to={article.slug} title={article.title}>
          <Content>
            <div className={styles.thinkingArticleContainer}>
              {article.image && (
                <div className={styles.thinkingArticleImageContainer}>
                  <div className={styles.thinkingArticleBorderYellow} />
                  <CircleContainer className={styles.thinkingArticleCircle}>
                    <Image
                      {...article.image}
                      className={styles.thinkingArticleImage}
                    />
                  </CircleContainer>
                </div>
              )}
              <div className={styles.thinkingArticleText}>
                <span className={`${styles.thinkingArticleDate}`}>
                  {article.date}
                </span>
                <h3 className={styles.thinkingArticleTitle}>{article.title}</h3>
                <div className={styles.moreLinkContainer}>
                  <div className={cx(stylesReadMore.moreLink)}>
                    <span
                      className={cx(
                        stylesReadMore.moreLinkSpan,
                        stylesReadMore.text,
                        stylesReadMore.blackText,
                      )}>
                      Read article
                    </span>
                    <span className={stylesReadMore.icon}>
                      <ArrowIcon color={NeutralPaletteNeutral10} />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Content>
        </InternalLink>
      </div>
      <LottieAnimation
        ref={animationRef}
        url={animation}
        maxSpeed={30}
        progress={progress}
      />
    </div>
  );
};

export const featuredThinkingArticleyQuery = graphql`
  query FeaturedThinkingArticleQuery {
    allContentfulThinking(
      sort: { fields: date, order: DESC }
      filter: {
        title: { ne: "__DUMMY_ITEM__" }
        priorityThinking: { eq: true }
      }
      limit: 1
    ) {
      nodes {
        id
        slug
        title
        priorityThinking
        date(formatString: "DD/MM/YY")
        image {
          gatsbyImageData
          file {
            url
          }
          title
        }
      }
    }
    contentfulComponentPageHeader(title: { eq: "Thinking" }) {
      textColour
      title
      description {
        description
      }
    }
  }
`;

export default FeaturedThinkingArticle;
